var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h1',{staticClass:"title font-weight-medium primary--text"},[_vm._v(" Registros pendientes ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('date-picker',{attrs:{"range":"","format":"DD-MM-YYYY","placeholder":"Seleccionar rango de Fechas"},on:{"input":function (val) {
							_vm.listenDatePicker(val);
						}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":[{ id: null, name: 'Todas las inspecciones' }].concat( _vm.inspections ),"label":"Todas las inspecciones","item-text":"name","item-value":"id","single-line":"","dense":"","hide-details":"","solo":""},on:{"input":_vm.changeFilter},model:{value:(_vm.filters.inspection_id),callback:function ($$v) {_vm.$set(_vm.filters, "inspection_id", $$v)},expression:"filters.inspection_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.registerStatus,"label":"Todos los estados","item-text":"label","item-value":"value","single-line":"","dense":"","hide-details":"","solo":""},on:{"input":_vm.changeStatusFilter},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar por Área/Taller/Estación","single-line":"","dense":"","hide-details":"","solo":""},on:{"input":function($event){return _vm.listenTextSearch()}},model:{value:(_vm.filters.q),callback:function ($$v) {_vm.$set(_vm.filters, "q", $$v)},expression:"filters.q"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.registers_revision,"items-per-page":-1,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Cargando...","no-data-text":"No se encontraron resultados"},scopedSlots:_vm._u([{key:"item.inspection",fn:function(ref){
					var item = ref.item;
return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(item.inspection.name)}})]}},{key:"item.date",fn:function(ref){
					var item = ref.item;
return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(_vm.formatDateString(item.date))}})]}},{key:"item.manager",fn:function(ref){
					var item = ref.item;
return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(item.manager_names)}})]}},{key:"item.compliance",fn:function(ref){
					var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium"},[_c('span',{staticClass:"grey--text mr-2",domProps:{"textContent":_vm._s(_vm.getCompliance(item) + '%')}})])]}},{key:"item.status",fn:function(ref){
					var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","color":_vm.getStatusRegister(item.status).color}},[_vm._v(" "+_vm._s(_vm.getStatusRegister(item.status).label)+" ")])]}},{key:"item.show",fn:function(ref){
					var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","text":"","link":"","to":{
									name: 'InspectionsRegisterRevision',
									params: {
										inspectionId: item.inspection.format,
										id: item.id,
									},
								}}},[_vm._v(" Ver "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-right ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"text-center py-2"},[(_vm.registers_revision_pagination)?_c('v-pagination',{attrs:{"length":_vm.registers_revision_pagination.pages || 0},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }