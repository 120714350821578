import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import {
	registerStatus,
	isPending,
	getStatus,
} from '../../../../store/services/register-status';
export default {
	name: 'ListRevision',
	data: () => ({
		title: '',
		filters: {
			q: '',
			start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
			end_date: moment().format('YYYY-MM-DD'),
			status: null,
			pagination: 1,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			page: 1,
		},
		menu: false,
		modal: false,
		menu2: false,
		breadcrumbs: [],
		headers: [],
		dateRange: [
			new Date(moment().subtract(1, 'months').format()),
			new Date(moment().format()),
		],
		loading: false,
		registerStatus: [
			...[{ label: 'Todos los estados', value: '' }],
			...registerStatus,
		],
	}),
	computed: {
		startDate() {
			return this.formatDate(this.filters.start_date);
		},
		endDate() {
			return this.formatDate(this.filters.end_date);
		},
		inspections() {
			let inspections = this.$store.state.inspection.inspections || [];
			if (Array.isArray(inspections)) {
				inspections = inspections.map((inspection) => {
					return {
						...inspection,
						name: `${inspection.code} - ${inspection.name}`,
					};
				});
			}
			return inspections;
		},
		...mapState('register', ['registers_revision', 'registers_revision_pagination']),
	},
	created() {
		this.initialize();
	},
	mounted() {
		$EventBus.$emit('showSubNav', false);
	},
	methods: {
		...mapActions('register', ['revision', 'pdf']),
		...mapActions('inspection', ['list']),
		getData() {
			this.loading = true;
			let filters = { ...this.filters };
			if ((filters?.q || '').length < 2) {
				delete filters.q;
			}
			this.revision({
				success: () => {
					this.loading = false;
				},
				fail: () => {
					this.loading = false;
				},
				filters: filters,
			});
		},
		getInspections() {
			this.list({
				success: () => {},
				fail: () => {},
			});
		},
		initialize() {
			this.getInspections();
			this.setHeaders();
			this.setBreadCrumbs();
			// this.filters.start_date = moment().weekday(1).format('YYYY-MM-DD');
		},
		setBreadCrumbs() {
			this.breadcrumbs = [
				{
					text: 'REVISIONES',
					disabled: true,
					href: '/',
				},
			];
		},
		goRegisterView(id) {
			this.$router.push('/dashboard/inspections/revision/' + id);
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		formatDateString(date) {
			let dateString = '';
			if (date) {
				let d = date.toString().substring(0, 10).split('-');
				dateString = `${d[2]}-${d[1]}-${d[0]}`;
			}
			return dateString;
		},
		changeFilter() {
			this.filters.page = 1;
			this.getData();
		},
		setHeaders() {
			this.headers = [
				{
					text: 'Inspección',
					value: 'inspection',
					align: 'start',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Fecha',
					value: 'date',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Área/Taller/Estación',
					value: 'area_workshop_station',
					align: 'center',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Porcentaje de cumplimiento',
					value: 'compliance',
					align: 'center',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Estado',
					value: 'status',
					align: 'center',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Acciónes',
					value: 'show',
					align: 'center',
					sortable: false,
					class: 'primary--text',
				},
			];
		},
		changeStatusFilter() {
			if (isPending(this.filters.status)) {
				this.filters.start_date = null;
				this.filters.end_date = null;
			}
			this.changeFilter();
		},
		isPendingStatus(value) {
			return isPending(value);
		},
		getStatusRegister(value) {
			return getStatus(value);
		},
		getCompliance(item) {
			let compliance = (100 * item.items_yes) / (item.items_yes + item.items_no);
			if (isNaN(compliance)) {
				compliance = 0;
			} else {
				compliance = parseFloat(parseFloat(compliance.toString()).toFixed(0));
			}
			return compliance;
		},
		clearEndDate() {
			this.filters.end_date = null;
			this.changeFilter();
		},
		clearStartDate() {
			this.filters.start_date = null;
			this.changeFilter();
		},
		listenTextSearch() {
			if ((this.filters.q || '').length > 1 || this.filters.q == '') {
				this.changeFilter();
			}
		},
		listenDatePicker(val) {
			this.filters.start_date = val[0] ? moment(val[0]).format('YYYY-MM-DD') : '';
			this.filters.end_date = val[1] ? moment(val[1]).format('YYYY-MM-DD') : '';
			this.filters.page = 1;
			this.getData();
		},
	},
	watch: {
		$route: {
			handler: function (value) {
				if (value.name == 'InspectionsRegisterListRevision') {
					this.getData();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
